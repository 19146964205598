















































































































































































































.searchbar {
  width: 100%;
  padding: 10px 18px;
  font-size: 18px;
  margin-bottom: 16px;
  &:focus {
    outline: 0;
  }
}

.loading {
  width: 100%;
  text-align: center;
}

.container {
  margin: 0 auto;
  max-width: 70rem;
}
