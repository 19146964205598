


















































































































































































.below-table {
  text-align: center;
  margin: auto;
  padding: 0.3rem; 
  font-size: 1.2rem;
  white-space: nowrap;
}

