















































































#username-display {
  text-transform: lowercase;
}

#main-sidebar {
  background-color: white;
}

.sidebar-contents {
  text-align: center;
}

.sidebar-row {
  margin-top: 1rem;
}

